import {gsap, TimelineLite, Elastic, TweenLite } from "gsap"
import { isEmpty } from "lodash";

gsap.registerPlugin(TimelineLite, Elastic, TweenLite); 

import { mapState, mapMutations } from 'vuex'
import { Financial } from '../Utils/Financial'
export default {
  data() {
    return {
      REGEX_PLACA_GENERICA: /^([a-zA-Z]{3})([0-9]([a-zA-Z]|[0-9])[0-9]{2})$/,
      REGEX_PLACA_ANTIGA: /^([a-zA-Z]{3})([0-9])([0-9])([0-9]{2})$/,
      REGEX_PLACA_NOVA: /^([a-zA-Z]{3})([0-9])([a-zA-Z])([0-9]{2})$/,
      REGEX_REPLACE: /[^0-9A-Za-z]/g,
      formData: {},
      formState: {
        activeField: 0,
        isNext: true,
        formLength: this.formFields.length,
        isComplete: false,
        isValid: false,
        prevStep: [],
        showResult: false,
        stepPrev: 0,
        isLoadingModels: false,
        modelsBySelection: [],
      },
    }
  },
  computed: {
    ...mapState({
      form: state => state.lead.formData
    }),
    activeFieldName() {
      if (!this.formFields[this.formState.activeField]) {
        return
      }
      if (this.formFields[this.formState.activeField] && this.formFields[this.formState.activeField].type == 'inputs') {
        let fields = this.formFields[this.formState.activeField].fields.map(field => {
          return { ...field }
        })
        return fields.map(f => {
          return { ...this.fields[f.name] }
        })
      }
      return this.fields[this.formFields[this.formState.activeField].name]
    },
    isCurrentFieldValid() {
      if (this.activeFieldName && Array.isArray(this.activeFieldName)) {
        let valid = true
        this.activeFieldName.map(e => {
          if (e && e.invalid) {
            valid = false
            return valid
          }
        })
        return valid
      }
      return this.activeFieldName && this.activeFieldName.valid

    },
    isLastField() {
      return (this.formState.activeField + 1) === this.formState.formLength ? true : false
    }
  },
  watch: {
    isLastField(newValue) {
      newValue ? (this.formState.isComplete = true) : (this.formState.isComplete = false)
    },
    isCurrentFieldValid(newValue) {
      newValue ? (this.formState.isValid = true) : (this.formState.isValid = false)
      if (newValue && this.formFields[this.formState.activeField].next && this.formState.isNext) {
       this.next();
      }
    },
    'form.manufacturingYear': function (newValue, oldValue) {
      if(newValue && newValue != oldValue) {
      this.clearField('modelYear');
      }
    },
    'form.modelYear': function (newValue, oldValue) {
      if(newValue && newValue != oldValue) {
       this.clearField('brand');
      }
    },
    'form.brand': function (newValue, oldValue) {
       this.clearField('model');
      if(newValue && newValue != oldValue) {
        this.getModels();
      }
    },
    'form.model': function (newValue, oldValue) {
       this.clearField('numberOfDoors');
      if(newValue && newValue != oldValue) {
        this.getNumberOfDoorsByModels();
      }
    },
    'form.numberOfDoors': function (newValue, oldValue) {
      this.clearField('version');
      if(newValue && newValue != oldValue) {
        this.getVersions();
      }
    },
    'form.version': function (newValue, oldValue) {
      this.clearField('fuelType');
      if(newValue && newValue != oldValue) {
        this.getFuelTypes();
      }
    },
    'form.fuelType': function (newValue, oldValue) {
      this.clearField('accessoryPackage');
      if(newValue && newValue != oldValue) {
        this.getAccessoryPackage();
      }
    },
    'formState.activeField': function (newValue, oldValue) {
    },
    'formState.stepPrev': function (newValue) {
    }
  },
  updated(){
    let prevStep = this.formState.prevStep[ this.formState.prevStep.length - 1] || 0
    this.formState.activeField > prevStep && this.formState.prevStep.push(this.formState.activeField)
    // this.formState.prevStep[ this.formState.prevStep.length - 1] < this.formState.activeField ? this.formState.prevStep.push(this.formState.activeField) : null
  },
  created() {
   // this.formData = { ...this.form }
    this.formState.activeField = this.$route.query.step ? parseInt(this.$route.query.step) : 0;
    this.formData.name = this.$route.query.name ? this.$route.query.name : '';
    this.formData.email = this.$route.query.email ? this.$route.query.email : '';
    this.formData.phone = this.$route.query.phone ? this.$route.query.phone : '';
    // this.$store.commit('lead/updateFormData', { 'name': this.$route.query.name, 'email': this.$route.query.email, 'phone': this.$route.query.phone })
    //this.$route.query.name ? (this.$store.commit('lead/updateFormData', { 'name': this.$route.query.name })) : ''
    

    if (this.$route.query.objetivo && (this.$route.query.objetivo == 'comprar' || this.$route.query.objetivo == 'financiar' || this.$route.query.objetivo == '1')) {
      this.formData.objetivo = "1";
      this.formData.product = 'Financiamento'
      // this.$store.commit('lead/updateFormData', { 'objetivo': '1', 'product': 'Financiamento' })
    }
  },
  mounted() {
    gtag('event', 'conversion', {'send_to': 'AW-10812990359/t7dhCKOP1ZQDEJfHhKQo'});
  },
  methods: {
    toFindDuplicates(arry) {
      let unique = [];
      for (let i = 0; i < arry.length; i++) {
        if (unique.indexOf(arry[i]) == -1) {
          unique.push(arry[i]);
        }
      }
      return unique;
    },
    getModels() {
      this.loading.model = true
      let options = this.formFields.find(e => e.type == 'inputs' && e.fields.find(f => f.name == 'model')).fields.find(f => f.name == 'model').options.choices.items
      options.length = 0
      this.$axios.post(process.env.VUE_APP_URL_GET_MODELS, {
        brand: this.form.brand,
        modelYear: this.form.modelYear}).then(response => {
          if(response.status == 200 || response.status == 201) {
            if(isEmpty(response.data) || response.data.items.length == 0) {
              options.push('Estamos com problemas para carregar os modelos. Tente novamente mais tarde.');
              return
            }
            let models = response.data.items
            this.formState.modelsBySelection = models
            let unique = [];
            for (let i = 0; i < models.length; i++) {
              if (unique.indexOf(models[i].model) == -1) {
                unique.push(models[i].model);
                options.push(models[i].model);
              }
            }
          }
        this.loading.model = false
      })
    },
    getNumberOfDoorsByModels() {
      if(this.form.model === null) return
      
      this.loading.doors = true
      let modelsBySelection = this.formState.modelsBySelection.filter(e => e.model === this.formData.model)
      let fieldOptions = this.formFields.find(e => e.type == 'inputs' && e.fields.find(f => f.name === 'numberOfDoors')).fields.find(f => f.name === 'numberOfDoors')
      fieldOptions.options.choices.items.length = 0
      let unique = [];
      for (let i = 0; i < modelsBySelection.length; i++) {
        if (unique.indexOf(modelsBySelection[i].numberOfDoors) == -1) {
          unique.push(modelsBySelection[i].numberOfDoors);
          fieldOptions.options.choices.items.push(modelsBySelection[i].numberOfDoors);
        }
      }
      if (fieldOptions.options.choices.items.length <= 1) {
        // this.form.numberOfDoors = fieldOptions.options.choices.items[0]
        this.$store.commit('lead/updateField', { key:'numberOfDoors', value: fieldOptions.options.choices.items[0] })
        fieldOptions.show = false
        this.clearField('version')
        this.getVersions()
      }else {
        fieldOptions.show = true
      }
      return
    },
    getFuelTypes() {
      if(this.form.model === null) return
      
      this.loading.fuelType = true
      let modelsBySelection = this.formState.modelsBySelection.filter(e => e.model === this.form.model && e.numberOfDoors === this.form.numberOfDoors && e.modelVersion === this.form.version)
      let fieldOptions = this.formFields.find(e => e.type == 'inputs' && e.fields.find(f => f.name === 'fuelType')).fields.find(f => f.name === 'fuelType')
      fieldOptions.options.choices.items.length = 0
      let unique = [];
      for (let i = 0; i < modelsBySelection.length; i++) {
        if (unique.indexOf(modelsBySelection[i].fuelType) == -1) {
          unique.push(modelsBySelection[i].fuelType);
          fieldOptions.options.choices.items.push(modelsBySelection[i].fuelType);
        }
      }
      if (fieldOptions.options.choices.items.length <= 1) {
        // this.form.fuelType = fieldOptions.options.choices.items[0]
        this.$store.commit('lead/updateField', { key:'fuelType', value: fieldOptions.options.choices.items[0] })
        fieldOptions.show = false
        this.clearField('accessoryPackage')
        this.getAccessoryPackage()
      }else {
        fieldOptions.show = true
      }
      return
    },
    getAccessoryPackage() {
      if(this.form.model === null) return
      
      this.loading.accessoryPackage = true
      let modelsBySelection = this.formState.modelsBySelection.filter(e => e.model === this.form.model && e.numberOfDoors === this.form.numberOfDoors && e.modelVersion === this.form.version)
      let fieldOptions = this.formFields.find(e => e.type == 'inputs' && e.fields.find(f => f.name === 'accessoryPackage')).fields.find(f => f.name === 'accessoryPackage')
      fieldOptions.options.choices.items.length = 0
      let unique = [];
      for (let i = 0; i < modelsBySelection.length; i++) {
        if (unique.indexOf(modelsBySelection[i].accessoryPackage) == -1) {
          unique.push(modelsBySelection[i].accessoryPackage);
          fieldOptions.options.choices.items.push(modelsBySelection[i].accessoryPackage);
        }
      }
      if (fieldOptions.options.choices.items.length <= 1) {
        // this.formData.fuelType = fieldOptions.options.choices.items[0]
        this.$store.commit('lead/updateField', { key: 'accessoryPackage', value: fieldOptions.options.choices.items[0] })
        fieldOptions.show = false
      }else {
        fieldOptions.show = true
      }
      return
    },
    getVersions() {
      if(this.form.numberOfDoors  === null) return
      this.loading.version = true
      let fieldOptions = this.formFields.find(e => e.type == 'inputs' && e.fields.find(f => f.name === 'version')).fields.find(f => f.name === 'version')
      fieldOptions.options.choices.items.length = 0
      let versions = this.formState.modelsBySelection.filter(e => e.model === this.form.model && e.numberOfDoors === this.form.numberOfDoors)
      let unique = [];
      console.log('getVersions', versions)
      for (let i = 0; i < versions.length; i++) {
        if (unique.indexOf(versions[i].modelVersion) == -1) {
          unique.push(versions[i].modelVersion);
          fieldOptions.options.choices.items.push(versions[i].modelVersion);
          console.log('getVersions dentro do if', versions[i])

        }
      }
      if (fieldOptions.options.choices.items.length <= 1) {
        // this.form.version = fieldOptions.options.choices.items[0]
        this.$store.commit('lead/updateField', { key: 'version', value: fieldOptions.options.choices.items[0] })
        fieldOptions.show = false
        this.clearField('fuelType')
        this.getFuelTypes()
      }else {
        fieldOptions.show = true
      }
      return
    },
    updateStepPrev(step) {
      this.formState.stepPrev = step
    },
    clearField(field) {
      // if (this.formData[field]) {
      //   this.formData[field] = null
      // }
      this.formData[field] = null
      this.$store.commit('lead/updateField',{key: field, value: null} )
      return
    },
    ...mapMutations({
      updateField: "lead/updateField",
      updateFormData: "lead/updateFormData"
    }),
    next() {
      this.formState.isNext = true
      this.isCurrentFieldValid ? this.nextStep() : this.decline(".field-area")
    },
    back() {
       this.formState.isNext = false
       this.formState.prevStep.pop()
       this.formState.activeField = this.formState.prevStep[this.formState.prevStep.length - 1] || 0
       this.formData[this.formFields[this.formState.activeField].name] = null
       this.$store.commit('lead/updateField', {key: this.formFields[this.formState.activeField].name, value: null})
      //  this.formState.stepPrev--
      // this.formData[this.formFields[this.formState.prevStep.slice(-1)[0]].name] = ''
      // if (this.formState.activeField > 0 && this.formFields[this.formState.activeField - 1].hasOwnProperty('if')) {
      // this.formData[this.formFields[this.formState.activeField].name] = null
      //   this.formState.activeField > 0 ? this.formState.activeField -= 1 : ""
       // //  this.formState.prevStep.pop()
      //   //return ;
      // }
      // this.formData[this.formFields[this.formState.activeField].name] = null
      // // this.formState.prevStep.pop()
      // this.formState.activeField > 0 ? this.formState.activeField-- : ""
      // return
    },
    submit() {
      var _hsq = window._hsq = window._hsq || [];
      if (!this.isCurrentFieldValid) {
        this.decline(".field-area")
        _hsq.push(['refreshPageHandlers']);
        _hsq.push(["identify",{
          email: this.form.email,
          phone: this.form.phone,
        }]);
        _hsq.push(['setPath', '/formulario/final']);
        _hsq.push(['trackPageView']);
      } else {
        this.formState.isNext = true
        this.formState.activeField++;
        this.$store.commit('lead/showResult')
        // this.$store.commit('lead/dataResult', this.getResultado(parseFloat(this.moedaParaNumero(this.formData.value.replace(/[^0-9./,-]+/g, ""))), parseInt(this.formData.prazo), this.formData.product == "Financiamento" ? 0.58 : 0.79, this.formData.product == "Financiamento" ? 0.79 : 0.99))
        const tipoImovel = ['Casa', 'Apartamento', 'Sala Comercial', 'Outros', 'Terreno em condomínio', 'Terreno'];
        const tipoImovelOutros = ['Terreno', 'Galpão', 'Fabrica', 'Outros', 'Terreno em condomínio'];
        const objetivo = ['Comprar imóvel ', 'Quitar dívidas', 'Investir', 'Capital de giro'];
        let utm_source = this.$cookies.get("utm_source");
        let utm_medium = this.$cookies.get("utm_medium");
        let utm_campaign = this.$cookies.get("utm_campaign");
        let utm_term = this.$cookies.get("utm_term");
        let utm_content = this.$cookies.get("utm_content");
        let ref_landing = this.$cookies.get("ref_landing");
        let ref_referer = this.$cookies.get("ref_referer");
        let utm_ref = this.$cookies.get("utm_ref");
        
        this.$axios.post(process.env.VUE_APP_URL_SEND_FORM, {
          utm_term,
          utm_content,
          ref_referer,
          ref_landing,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_ref,
          formData: this.form,
          userAgent: this.form.userAgent,
          clientIp: this.form.clientIp,
          // userIp: navigator.i,
          // 'product': this.formData.product,
          // 'objetivo': objetivo[this.formData.objetivo - 1],
          // 'tipo_imovel': this.formData.type_property === '4' ? tipoImovelOutros[this.formData.type_property_other - 1] : tipoImovel[this.formData.type_property - 1],
          // 'parcelas': parseInt(this.formData.prazo),
          'name': this.form.name,
          'email': this.form.email,
          'cpf': this.form.cpf,
          'telefone': this.form.phone,
          //'valor_garantia': parseFloat(this.moedaParaNumero(this.form.property_value.replace(/[^0-9./,-]+/g, ""))).toFixed(2),
          'renda': this.form.renda,
          'placa': this.form.plate || '',
          'cep': this.form.cep || '',
          'veiculo_value_open': this.form.veiculo_value_open || '',
          'data_nascimento': this.form.birthday,
          'tempo_de_compra': this.form.tempo_compra ? this.form.tempo_compra : '',
          'name_empresa': this.form.name_empresa ? this.form.name_empresa : '',
          'cnpj': this.form.cnpj ? this.form.cnpj : '',
          'data_fundacao': this.form.data_fundacao ? this.form.data_fundacao : '',
          'faturamento': this.form.faturamento ? this.form.faturamento : '',
          //'valor_credito': parseFloat(this.moedaParaNumero(this.formData.value.replace(/[^0-9./,-]+/g, ""))).toFixed(2)
        }).then(res => {
          if (res.status === 200) {

            let value_conversion = this.form.value.replace(/[^0-9./,-]+/g, "");
            if(value_conversion > 999000){
              value_conversion = value_conversion / 100
            }
            value_conversion = (this.moedaParaNumero(value_conversion) * 0.005).toFixed(2)
            this.$gtag.event('generate_lead', {
              'event_cacurrency': 'BRL',
              'value': value_conversion,
            })
           // value_conversion = value_conversion.replace(/[^0-9./,-]+/g, "").toFixed(2)
            // CASO CGV
            if(this.form.veiculo_value 
              && this.form.negativado == "nao"
              && (this.moedaParaNumero(this.form.veiculo_value.replace(/[^0-9./,-]+/g, "")) > 20000)
              && (this.moedaParaNumero(this.form.value.replace(/[^0-9./,-]+/g, "")) > 5000)
              && (this.moedaParaNumero(this.form.renda.replace(/[^0-9./,-]+/g, "")) > 3000)){
              gtag('event', 'conversion', {
                'send_to': 'AW-10812990359/eIrJCPvl76kDEJfHhKQo',
                'value': value_conversion,
                'currency': 'BRL'
            });
          }else
            if(this.form.cnpj 
              && this.form.negativado == "nao"
              && (this.moedaParaNumero(this.form.faturamento.replace(/[^0-9./,-]+/g, "")) > 10000)){
              gtag('event', 'conversion', {
                'send_to': 'AW-10812990359/WZJACIL-qKoDEJfHhKQo',
                'value': value_conversion,
                'currency': 'BRL'
            });
            }
            _hsq.push(['refreshPageHandlers']);
            _hsq.push(["identify",{
              email: this.form.email,
              phone: this.form.phone,
            }]);
            _hsq.push(["track", "Conversion"]);
            _hsq.push(['setPath', '/resultado-simulacao/success']);
            _hsq.push(['trackPageView']);
            gtag('event', 'conversion', {'send_to': 'AW-10812990359/uRqMCKScx6MDEJfHhKQo'});
            this.$store.commit('lead/dataResult',{
              valorSolicitado: this.form.value,
              codigoSimulacao: res.data
            })
            let urlWhats = "https://api.whatsapp.com/send?phone=554730483888&text=Olá, gostaria de saber mais sobre minha simulação numero: *" + res.data + "*"
           this.$store.commit('lead/setUrl', urlWhats);
            this.$store.commit('lead/setValid');
          }
          this.$store.commit('lead/toogleLoading');
        })

      }
    },
    nextStep() {
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', '/step/' + this.formFields[this.formState.activeField].name || this.formState.activeField]);
      // Track the page view for the new page
      _hsq.push(['trackPageView']);
      // this.$gtag.set('page_title', this.formFields[this.formState.activeField].name)
      this.$gtag.pageview('/step/' + this.formFields[this.formState.activeField].name)
      // this.$gtm('set','send_page_view', true);
      if (!this.isLastField && !this.formFields[this.formState.activeField + 1].hasOwnProperty('if')) {
        //this.formState.prevStep.push(this.formState.activeField)
        return this.formState.activeField++;
      }
      let c = false;
      let field = this.formState.activeField + 1;
      do {
        if (!this.formFields[field].hasOwnProperty('if')) {
             // this.formState.prevStep.push(field)
            return this.formState.activeField = field;
        }else{
        switch (this.formFields[field].if.operator) {
          case 'like':
            if (this.formFields[field].if.condition === this.form[this.formFields[field].if.model]) {
              this.formState.activeField = field;
              c = false;
            } else {
              field++
              c = true;
            }
            break;
          case 'in':
            if (this.formFields[field].if.condition.includes(this.form[this.formFields[field].if.model])) {
              this.formState.activeField = field;
              c = false;
            } else {
              field++
              c = true;

            }
            break;
          case 'not':
            if (this.formFields[field].if.condition != this.form[this.formFields[field].if.model]) {
              this.formState.activeField = field;
              c = false;
            } else {
              field++
              c = true;
            }
            break;
          case 'notIn':
            if (!this.formFields[field].if.condition.includes(this.form[this.formFields[field].if.model])) {
              this.formState.activeField = field;
              c = false;
            } else {
              field++
              c = true;
            }
            break;
        }

      }
      //this.formState.prevStep.push(field)
      } while (c)
    },
    getCondition(field) {
      if (!field.hasOwnProperty("if")) {
        // if(this.errors.items.length > 0 && this.errors.items.find(item => item.field == field.name)){
        //   return false
        // }
        return true
      }
      if(this.errors.items.length > 0 && this.errors.items.find(item => item.field !== field.name) && field.value == ''){
        return false
      }
      switch (field.if.operator) {
        case "like":
          if (field.if.condition === this.form[field.if.model]) {
            return true
          }
          break
        case "in":
          if (field.if.condition.includes(this.form[field.if.model])) {
            return true
          }
          break
        case "not":          
          if (field.if.condition != this.form[field.if.model]) {
            return true
          }
          break
        case "notIn":
          if (!field.if.condition.includes(this.form[field.if.model])) {
            return true
          }
          break
        default:
          return false
      }
      return false
    },
    decline(element) {
      this.$validator.validate()
      // Shake form area when the field is invalid
      var tl = new TimelineLite()
      // eslint-disable-next-line no-undef
      tl.add(TweenLite.to(element, 0.4, { y: -120 }));
      // eslint-disable-next-line no-undef
      tl.add(TweenLite.to(element, 0.4, {
        y: 0,
        className: "field-area is-danger",
        ease: Elastic.easeOut.config(0.9, 0.2)
      }));
    },
    // getResultado(valorSolicitado, prazo, taxaBest, taxaConcorrente) {
    //   let resultadoBest, resultadoOutros = {};
    //   let primeiraParcelaConcorrente, economiaMensal, economiaTotal, cet = 0
    //   if (this.formData.product == 'Financiamento') {
    //     taxaBest = 5.915
    //     resultadoBest = new Financial(valorSolicitado, taxaBest, prazo, this.formData.birthday, parseFloat(this.moedaParaNumero(this.formData.property_value.replace(/[^0-9./,-]+/g, ""))));
    //     resultadoBest.financiarSac();
    //     resultadoBest.formataDados();
    //     cet = resultadoBest.cet
    //   } else {
    //     resultadoBest = new Financial(valorSolicitado, taxaBest / 100, prazo, this.formData.birthday, parseFloat(this.moedaParaNumero(this.formData.property_value.replace(/[^0-9./,-]+/g, ""))));
    //     resultadoOutros = new Financial(valorSolicitado, taxaConcorrente / 100, prazo, this.formData.birthday, parseFloat(this.moedaParaNumero(this.formData.property_value.replace(/[^0-9./,-]+/g, ""))));
    //     resultadoBest.financiarPrice();
    //     resultadoOutros.financiarPrice();
    //     resultadoBest.formataDados();
    //     resultadoOutros.formataDados();
    //     primeiraParcelaConcorrente = resultadoOutros.pmt[0]
    //     economiaMensal = primeiraParcelaConcorrente - resultadoBest.pmt[0];
    //     economiaTotal = economiaMensal * prazo;
    //     cet = resultadoBest.cetRefin
    //   }


    //   // resultado.tratarMascaraReal();/* Remove a máscara de R$ */
    //   //resultado.formataDados(); /* Faz as conversões para Int e Float */

    //   let primeiraParcela = resultadoBest.pmt[0]
    //   let ultimaParcela = resultadoBest.pmt.slice(-1)[0]
    //   let valorFinanciado = resultadoBest.valorFinanciado


    //   let response = { cet, valorSolicitado, valorFinanciado, primeiraParcela, ultimaParcela, prazo, economiaMensal, economiaTotal, taxaBest, taxaConcorrente, primeiraParcelaConcorrente };
    //   return response;
    // },
    getParcela(valorFinanciado, prazoPagamento, taxa) {
      let taxaBest = parseFloat((taxa / 100).toFixed(15))
      let e = 1.0
      let cont = 1.0

      for (let k = 1; k <= prazoPagamento; k++) {
        cont = parseFloat((cont * (taxaBest + 1)).toFixed(15))
        e = e + cont
      }
      e = e - cont
      valorFinanciado = parseFloat(valorFinanciado * cont)
      return parseFloat((valorFinanciado / e).toFixed(2))
    },
    moedaParaNumero(valor) {
      let a = this.replaceAll(valor, '.', '')
      return parseFloat(a.replace(",", ".")).toFixed(2);
    },
    replaceAll(str, needle, replacement) {
      return str.split(needle).join(replacement);
    },
    convert(currency) {
      var k, temp;
      // Loop to make substring 
      for (var i = 0; i < currency.length; i++) {

        // Getting Unicode value 
        k = currency.charCodeAt(i);

        // Checking whether the character 
        // is of numeric type or not 
        if (k > 47 && k < 58) {

          // Making substring 
          temp = currency.substring(i);
          break;
        }
      }

      // If currency is in format like 
      // 458, 656.75 then we used replace 
      // method to replace every ', ' with '' 
      temp = temp.replace(/, /, '');

      // Converting string to float 
      // or double and return 
      return parseFloat(temp).toFixed(2);


    }
  }
}
