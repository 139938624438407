<script>
import { getComponent } from "./ComponentTypes"
import formDefault from "../config/formConfig.json"
import formCgv from "../config/formCgv.json"
import formCgi from "../config/formCgi.json"
import formFinan from "../config/formFinan.json"
import formConsignado from "../config/formConsignado.json"
import formPessoal from "../config/formPessoal.json"
import formCg from "../config/formCg.json"
import { mapMutations } from "vuex"

let formConfig = formDefault
export default {
  name: "FormConfigProvider",
  functional: true,
  provide() {
    return {
      formState: this.formState,
    }
  },
  props: {
    queryVar: {
      type: Object,
      default: () => ({})
    }
  },
  ...mapMutations({
    setFormConfig: "setFormConfig",
  }),
  render(createElement, { props }) {
    if (props.query_var) {
      switch (props.query_var.produto) {
        case "cgv":
          formConfig = formCgv
          break
        case "cgi":
          formConfig = formCgi
          break
        case "cg":
          formConfig = formCg
          break
        case "finan":
          formConfig = formFinan
          break
        case "consignado":
          formConfig = formConsignado
          break
        case "pessoal":
          formConfig = formPessoal
          break
        default:
          formConfig = formDefault
          break
      }
    } else {
      formConfig = formDefault
    }
    let fieldObjects = formConfig.map((field) => {
      if (field.type === "inputs") {
        let fields = field.fields.map((fields) => {
          let component = getComponent(fields.type)
          return { ...fields, component }
        })
        return { ...field, fields }
      } else {
        let component = getComponent(field.type)
        return { ...field, component }
      }
    })
    return createElement(getComponent("formTemplate"), {
      props: {
        formFields: fieldObjects,
      },
    })
  },
}
</script>
