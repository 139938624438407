<template>
  <div>
    <!-- <label
      v-for="(option, key) in options"
      :key="key"
      class="l"
    > -->
    <v-autocomplete
      :id="id"
      v-model="selectedValues"
      :items="items"
      outlined
      :name="name"
      :clearable="clearable"
      :auto-select-first="options.autoSelectFirst || true"
      :error-messages="errorMessages"
      placeholder="Comece a digitar para buscar"
      :label="label"
      validate-on-blur
      autocomplete="off"
      :value="value"
      no-data-text="Não encontrada, selecione uma opção"
      @focus="$emit('focus', $event)"
      @change="$emit('change', $event)"
    />
    <!-- </label> -->
    <!-- </div> -->
  </div>
</template>
<script>
import axios from 'axios'
export default {

  props: {
    // eslint-disable-next-line vue/require-prop-types
    options: {
      required: true,
      default: () => []
    },
    id: {
      required: false,
      default: () => "",
      type: String
    },
    name: {
      required: false,
      default: () => "",
      type: String
    },
    label: {
      required: false,
      default: () => "",
      type: String
    },
    clearable: {
      required: false,
      default: () => false,
      type: Boolean
    },
    errorMessages: {
      required: false,
      default: () => "",
      type: String
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      selectedValues: [],
      items: []
    }
  },
  watch: {
    selectedValues(newVal) {
      this.$emit("input", newVal)
    },
    value(newVal) {
      this.selectedValues = newVal
    }
  },
  created() {
      this.items = this.options.choices.items 
  if (this.value && this.value.length > 0) this.selectedValues = this.value
  },
  methods: {
  }
}
</script>
<style lang="scss">
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    line-height: 1.5!important
}
</style>
