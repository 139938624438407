<template>
  <div class="chooses">
    <label
      v-for="(option, key) in options"
      :key="key"
      class="choose"
    >
      <input
        type="radio"
        :value="getItemValue(option)"
        :checked="isItemChecked(option)"
        @input="$emit('input', $event.target.value)"
      >
      <div
        class="choose-body"
        :style="{ fontSize: getFontSize(option) + 'px' }"
      >
        <span v-if="option.IconSvg">
          <svg
            v-svg
            :symbol="option.IconSvg"
          />
        </span>
        <span
          v-else-if="getItemIcon(option)"
          class="icon"
          :style="{ backgroundImage: `url(${getItemIcon(option)})` }"
        />
        <div v-html="option.name" />
      </div>
      <div
        v-if="option.hint"
        id="tooltip"
      >{{ option.hint }}</div>
      <!-- <div v-if="option.optionalInput">
        <div v-for="(option, key) in option.optionalInput" :key="key">
          <label>
            {{ option.label }}
            <input @input="$emit('input', $event.target.value)" type="text" />
          </label>
        </div>
        <button class="form-button next" @click="$emit('next')">Próximo</button>
      </div> -->
    </label>
  </div>
</template>
<script>
import { isObject, isFunction, get as objGet } from "lodash"

export default {
  props: ["options", "value", "disabled"],
  methods: {
    getItemValue(item) {
      if (isObject(item)) {
        if (typeof item["value"] !== "undefined") {
          return item.value
        } else {
          throw "`value` is not defined. If you want to use another key name, add a `value` property under `radiosOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values"
        }
      } else {
        return item
      }
    },
    getItemIcon(item) {
      return item.icon
    },
    getItemName(item) {
      if (isObject(item)) {
        if (typeof item["name"] !== "undefined") {
          return item.name
        } else {
          throw "`name` is not defined. If you want to use another key name, add a `name` property under `radiosOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values"
        }
      } else {
        return item
      }
    },
    getFontSize(item) {
      let name = this.getItemName(item)
      if (name.length > 26) return 17
      return 20
    },
    getItemCssClasses(item) {
      return {
        "is-checked": this.isItemChecked(item),
        "is-disabled": this.isItemDisabled(item),
      }
    },
    onSelection(item) {
      let value = this.getItemValue(item)
      this.$emit('input', value)
      //    this.$emit('event_child', 1)
    },
    isItemChecked(item) {
      let currentValue = this.getItemValue(item) 
      return currentValue === this.value
    },
    isItemDisabled(item) {
      if (this.disabled) {
        return true
      }
      let disabled = objGet(item, "disabled", false)
      if (isFunction(disabled)) {
        return disabled(this.model)
      }
      return disabled
    },
  },
}
</script>
<style lang="scss" scope>
/*.field-chooses{*/
/*label{*/
/*text-align: center;*/
/*width: 100%;*/
/*font-size: 30px;*/
/*margin-bottom: 30px;*/
/*margin-top: 10px;*/
/*}*/
/*}*/

label {
  display: inline-block !important;
  margin-bottom: 0.5rem;
}
.chooses {
  text-align: center;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 700px;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
  .radio-list {
    label {
      display: block;
      input[type="radio"] {
        margin-right: 5px;
      }
    }
  }
  @media (min-width: 768px) {
    .choose {
      height: 180px;
      width: 180px;
    }
  }
  @media (max-width: 767px) {
    .choose {
      -webkit-box-flex: 0 !important;
      flex: 0 0 25% !important;
      width: 50% !important;
      margin: 0 !important;
      margin-right: 0.8rem !important;
      margin-bottom: 0.8rem !important;
      .choose-body {
        width: 140px !important;
        height: 140px !important;
        font-size: 15px !important;
        .icon {
          background-size: contain !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          width: 30px !important;
          height: 30px !important;
        }
      }
      input[type="checkbox"]:checked + .choose-body,
      input[type="radio"]:checked + .choose-body {
        box-shadow: 4px 8px 10px -1px #00b12b;
        border-width: 2px;
        border-color: #00b12b;
        color: #00b12b;
        -webkit-transform: scale(1.02) !important;
        transform: scale(1.02) !important;
      }
    }
  }
  .choose {
    position: relative;
    border-radius: 4px;
    vertical-align: top;
    margin: 15px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
    .choose-body {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-box-align: center;
      align-items: center;
      background: #fff;
      border: 1px solid #d9e2ec;
      border-radius: 4px;
      color: #627d98;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      /*font-size: 25px;*/
      height: 100%;
      -webkit-box-pack: center;
      justify-content: center;
      padding: 5px;
      text-align: center;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      .icon {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 70px;
      }
    }
    input[type="checkbox"]:checked + .choose-body,
    input[type="radio"]:checked + .choose-body {
      box-shadow: 0px 0px 15px 1px #2c3d4f;
      border-width: 1px;
      border-color: #fff;
      color: #0041b1;
      transform: scale(1.1);
      // background-color: #3e8fec;
    }
    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
      //  background: #7ed957;
    }
    input[type="radio"]:checked:before {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmZmZmYnIHdpZHRoPScxNCcgaGVpZ2h0PScxMSc+PHBhdGggZD0nTTQuNiwxMC42TDAuMiw2LjFjLTAuMi0wLjItMC4yLTAuNiwwLTAuOWwwLjktMC45QzEuMyw0LDEuNyw0LDIsNC4zbDMuMSwzLjFsNi45LTdjMC4yLTAuMiwwLjctMC4yLDAuOSwwbDAuOSwwLjljMC4yLDAuMiwwLjIsMC42LDAsMC45bC04LjMsOC4zQzUuMywxMC44LDQuOSwxMC44LDQuNiwxMC42Jy8+PC9zdmc+);
      position: relative;
      top: 1px;
      border-radius: 50px;
      border-color: #0042b1;
      border-style: "solid";
      display: inline;
      height: auto;
      vertical-align: baseline;
      width: auto;
      z-index: 1;
      filter: brightness(0.1);
    }
    &:hover {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    &:focus {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    &:active {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  .choose,
  .choice-inline {
    cursor: pointer;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    background: 0 0;
    border-radius: 50%;
    height: 28px;
    line-height: 24px;
    width: 28px;
    margin-top: 0;
    position: absolute;
    left: 14px;
    top: 7px;
    text-align: center;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    font-size: initial;
  }
}
</style>
