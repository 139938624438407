/* eslint-disable vue/no-unused-vars */
<template>
  <div>
    <!-- <label :for="id">{{ label }}  <span> {{ description }} </span> </label> -->

    <v-text-field
      v-if="!isMoney"
      :id="id"
      outlined
      :type="type"
      :label="label"
      :hint="hint"
      :name="name"
      :error-messages="errorMessages"
      :data-vv-as="label"
      :value="value"
      :loading="loading"
      autocomplete="off"
      loader-height="4"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @keyup="$emit('keyup', $event)"
      @click="$emit('click', $event)"
      @blur="$emit('blur', $event)"
      @keypress="$emit('keypress', $event)"
    />
    <v-text-field
      v-else
      v-validate="vvalidate"
      v-currency="money"
      outlined
      :hint="hint"
      loader-height="4"
      :label="label"
      :loading="loading"
      :error-messages="errorMessages"
      autocomplete="off"
      :name="name"
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @keyup="$emit('keyup', $event)"
      @click="$emit('click', $event)"
      @blur="$emit('blur', $event)"
      @keypress="$emit('keypress', $event)"
    />
    <!-- <v-input v-else
      :type="type"
      v-bind="{...options.attrs}"
      @input="$emit('input', $event)"
      :name="name"
      locale="pt-BR"
      currency="BRL"
      :auto-decimal-mode="false"
      :precision="2"
      :distraction-free="false"
    /> -->
  </div>
</template>
<script>
import { CurrencyDirective } from "vue-currency-input"

export default {
  directives: {
    currency: CurrencyDirective,
  },
  props: ["value", "hint", "name", "type", "id", "label", "description", "options", "isMoney", "vvalidate", "errorMessages", "loading"],
  data() {
    return {
      money: {
        locale: "pt-BR",
        currency: "BRL",
        valueAsInteger: true,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false,
      },
    }
  },
  methods: {
  },
}
</script>
<style>
.input{
  transition:all 0.3s ease;
}
.v-text-field .v-label {
    max-width: 100%!important;
}
</style>