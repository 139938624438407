// import formConfig from "../../config/formConfig.json"

const mutations = {
  setFormConfig(payload) {
   state.formData = payload
  },
  updateField(state, payload) {
    console.log('updateField', payload)
    state.formData[payload.key] = payload.value
  },
  showResult(state) {
    state.result = true
  },
  toogleLoading(state) {
    state.loading ? state.loading = false : state.loading = true
  },
  setValid(state) {
    state.valid ? state.valid = false : state.valid = true
  },
  dataResult(state, payload) {
    state.dataResult = payload
  },
  updateFormData(state, payload) {
    state.formData = payload
  },
  setUrl(state, payload) {
    state.url = payload
  },
}

const state = {
  formData: {
    atividade_profissional: '',
    birthday: null,
    cep: null,
    cpf: null,
    email: null,
    estado_civil: null,
    jobTitle: '',
    name: null,
    nameOfMother: null,
    negativado: null,
    numberOfResidence: null,
    objetivo: null,
    phone: null,
    plate: null,
    possui_imovel: null,
    possui_veiculo: null,
    renda: null,
    rg: null,
    score_serasa: null,
    timeOfEmployment: '',
    value: null,
    veiculo_value: null,
    modelYear: null,
    model: null,
    version: null,
    brand: null,
    numberOfDoors: null,
    fuelType: null,
    tipo_veiculo: null,
    manufacturingYear: null,
    accessoryPackage: null,
    optwhatsapp: true,
    userAgent: null,
    clientIp: null,
  },
  result: false,
  loading: true,
  dataResult: {
    cet: 0,
    valorSolicitado: 0,
    valorFinanciado: 0,
    primeiraParcela: 0,
    ultimaParcela: 0,
    prazo: 0,
    economiaMensal: 0,
    economiaTotal: 0,
    taxaBest: 0,
    taxaConcorrente: 0,
    primeiraParcelaConcorrente: 0,
  },
  url: '',
  valid: false
}


// formConfig.forEach(field => {
//   state.formData[field.name] = field.default ? field.default : ''
// })

export default {
  namespaced: true,
  mutations,
  state
}
